<template>
  <div class="mdc-tab-bar directionality-toggle" role="tablist">
    <div class="mdc-tab-scroller">
      <div class="mdc-tab-scroller__scroll-area">
        <div class="mdc-tab-scroller__scroll-content">
          <button v-for="(item, index) in tab_options" :key="index" class="mdc-tab" role="tab" aria-selected="true"
            tabindex="" :id="item.tab_id">
            <span class="mdc-tab__content">
              <span class="mdc-tab__text-label">{{ item.tab_name }}</span>
            </span>
            <span class="mdc-tab-indicator ">
              <span class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
            </span>
            <span class="mdc-tab__ripple mdc-ripple-upgraded"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { MDCTabBar } from '@material/tab-bar';

export default {
  name: 'Tab',
  props: {
    tab_options: { type: Array }
  },
  data() {
    return {
    };
  },
  mounted() {
    const _materialComponentTab = new MDCTabBar(document.querySelector('.mdc-tab-bar'));
    _materialComponentTab.listen('MDCTabBar:activated', (event) => {
      this.$emit('_toggle_tab', _materialComponentTab.tabList_[event.detail.index].id);
    });

    _materialComponentTab.foundation.adapter.activateTabAtIndex(0);
    _materialComponentTab.foundation.adapter.focusTabAtIndex(0);
  },
}
</script>



<style lang="scss">
@use "@material/tab-bar/mdc-tab-bar";
@use "@material/tab-scroller/mdc-tab-scroller";
@use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/tab/mdc-tab";
@use '@material/tab/mixins' as tab-mixins;

$color_white: var(--color-text-primary);

.mdc-tab-bar {
  background-color: var(--color-surface);
  margin-bottom: 20px;
}

.mdc-tab-indicator--active {
  background-color: var(--color-highlight);

  & .mdc-tab__text-label {
    color: var(--color-text-primary);
  }
}


.mdc-tab--active {
  @include tab-mixins.text-label-color($color_white);
}

.mdc-tab {
  @include tab-mixins.text-label-color($color_white);
}

@media (min-width: 600px) and (max-width: 839px) {
  .mdc-tab-scroller {
    margin: 0;
  }
}

@media (min-width: 840px) {
  .mdc-tab-scroller {
    margin: 0 15%;
  }
}

@media (min-width: 1280px) {
  .mdc-tab-scroller {
    margin: 0 30%;
  }
}
</style>
